<template>
  <span class="" v-if="blocked">
    <v-menu offset-y :nudge-bottom="2">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="area.cor" class="text-nonecase fs-8pt pt-1 px-1 me-1" v-bind="attrs" v-on="on" outlined x-small>
          <i v-i:ic#upload#18 class="btn-icon-left"></i>
          <span>importar plano</span>
        </v-btn>
      </template>
      <v-card>
        <v-list dense class="m-0 p-0">
          <v-list-item dense class="m-0 px-1" style="min-height: 26px;">
            <v-btn @click="importar" :color="area.cor" x-small text class="m-0 p-0 text-nonecase line-height-0 f-lato fs-10pt">
              <v-icon class="fs-12pt">mdi-file-word</v-icon>
              Documento Word (.docx)
            </v-btn>
          </v-list-item>
          <v-list-item dense class="m-0 px-1" style="min-height: 26px;">
            <v-btn disabled :color="area.cor" x-small text class="m-0 p-0 text-nonecase line-height-0 f-lato fs-10pt">
              <i v-i:duo#download#16 class="btn-icon-left ms-0"></i>
              Documento
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import XLSX from 'xlsx';
import moment from 'moment';
import apploading from './appLoading.vue';
import docx4js from "docx4js"

export default {
  name: "verplanoensino",
  components: {
    apploading,
    'ementaviewitem': () => import('@/views/cursos/planosv2/ementa/ementa-view-item.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {
    blocked() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        if(this.isUserAdmin) return true;
        if(gestores.indexOf(this.userLog.cargo) != -1 && this.userLog.areasIC[this.area.id] != undefined) {
          return true;
        }
      }
      return false;
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      conteudos: {},
      qtdeConteudos: 0,
      topicos: {},
      qtdeTopicos: 0,
      ordem: "",
      metas: {},
      qtdeMetas: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    console.log("this.uc.id",this.uc.id);
    rdb.ref('/curriculo/conteudos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.conteudos = {};
        self.qtdeConteudos = 0;
        self.ordem = "";
      } else {
        self.qtdeConteudos = linha.qtde;
        self.ordem = linha.ordem;
        self.conteudos = {};
        var vetOrdem = [];
        if(linha.ordem != undefined) {
          vetOrdem = linha.ordem.split(";");
        }
        for(var idx in vetOrdem) {
          self.conteudos[vetOrdem[idx]] = linha.conteudos[vetOrdem[idx]];
        }
      }
      self.refresh++;
      console.log("self.conteudos",self.conteudos);
    });

    rdb.ref('/curriculo/topicos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.topicos = {};
        self.qtdeTopicos = 0;
        self.ordem = "";
      } else {
        self.qtdeTopicos = linha.qtde;
        self.ordem = linha.ordem;
        self.topicos = {};
        var vetOrdem = [];
        if(linha.ordem != undefined) {
          vetOrdem = linha.ordem.split(";");
        }
        for(var idx in vetOrdem) {
          self.topicos[vetOrdem[idx]] = linha.topicos[vetOrdem[idx]];
        }
      }
      self.refresh++;
      console.log("self.topicos",self.topicos);
    });

    rdb.ref("/curriculo/metasNovas/").orderByChild("ucID").equalTo(self.uc.id).on('value',function(snapshot) {
      self.metas = {};
      self.qtdeMetas = 0;
      var metas = snapshot.val();
      if(metas != null) {
        self.metas = metas;
        self.qtdeMetas = Object.keys(metas).length;
      }
      console.log("self.metas",self.metas);
    });

  },
  methods:{

    build() {
      var self = this;
    },

    importar() {
      console.log("importar");
      console.log("this.uc",this.uc);
      console.log("docx4js",docx4js);
      docx4js.load("~/test.docx").then(function(docx) {
        console.log(docx);
      });
    }
  }
}
</script>

<style scoped>
</style>
